import React from 'react'
import heroImg from '../../assets/images/hero.svg'
import CountUp  from 'react-countup'


const Hero = () => {
  return (
    <section className="pt-0" id='about'>
        <div className="container pt-14 ">
            <div className="md:flex items-center justify-between sm:flex-col md:flex-row ">
                {/*============== Hero Left Content Start End =========== */}
                    <div className="w-full md:basis-1/2">
                        <h5 className="text-headingColor font-[600] text-[16px]" data-aos="fade-right" data-aos-duration='2000'>Hi, Welcome</h5>
                        <h1 className="text-headingColor font-[800] text-[1.8rem] sm:font-[40px] leading-[35px] sm:leading-[46px] mt-5" data-aos="fade-up" data-aos-duration='2000'>My name is Dimal Samintha.<br/>
                        Website Designer
                        </h1>
                        <div className="flex items-center gap-6 mt-7 " data-aos="fade-left" data-aos-duration='1800' data-aos-delay='200'>
                            <a href="http://www.google.com" className="#contact">
                                <button className="bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-[8px]">
                                    <i className="ri-mail-line">Hire me</i>
                                </button>
                            </a>
                            <a href="#portfolio" className="text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor ">See My Portfolio

                            </a>
                            
                        </div>
                       
                        <p className="flex gap-2 mt-12 text-headingColor font-[500] text-[15px] leading-7 sm:pl-14 sm:pr-10" data-aos="fade-left" data-aos-duration='1500'>
                        <span className="">
                            <i className="ri-apps-2-line"></i>
                        </span>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi eius possimus, quam enim, dolorum dolores doloremque in quis assumenda at molestias debitis unde. Soluta aperiam maiores iusto, rem tempora dolore.
                            </p>
                            <div className="flex items-center gap-9 mt-14 ">
                                <span className="text-smallTextColor text-[15px] font-[600] ">
                                    Follow me:
                                </span>

                                <span className="">
                                    <a href="http://www.google.com" className="text-smallTextColor text-[18px] font-[600]">
                                    <i class="ri-github-fill"></i>
                                    </a>
                                </span>
                                <span className="">
                                    <a href="https://www.facebook.com/profile.php?id=61560866365775" target='_blank' className="text-smallTextColor text-[18px] font-[600]">
                                    <i class="ri-facebook-line"></i>
                                    </a>
                                </span>
                                <span className="">
                                    <a href="https://www.linkedin.com/in/dimal-samintha-17020b299/" target='_blank' className="text-smallTextColor text-[18px] font-[600]">
                                    <i class="ri-linkedin-line"></i>
                                    </a>
                                </span>
                            </div>
                    </div>
                {/*============== Hero Left Content End =========== */}

                {/*============== Hero Image Start End =========== */}
                <div className="basis-1/3 mt-10 sm:mt-0 ">
                    <figure className="flex items-center justify-center ">
                        <img src={heroImg} alt="" className="" />
                    </figure>
                </div>
                {/*============== Hero Image End =========== */}

                {/*============== Hero Content Right Start =========== */}
                <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end ">
                <div className="mb-10 ">
                    <h2 className="text-headingColor font-[700] text-[32px] ">
                        <CountUp start={0} end={6} duration={3} suffix='+'/>
                    </h2>
                    <h4 className="text-headingColor font-[600] text-[18px]">Years of Experience</h4>
                </div>

                <div className="mb-10 ">
                    <h2 className="text-headingColor font-[700] text-[32px] ">
                        <CountUp start={0} end={100} duration={2} suffix='%'/>
                    </h2>
                    <h4 className="text-headingColor font-[600] text-[18px]">Success Rate</h4>
                </div>

                <div className="mb-10 ">
                    <h2 className="text-headingColor font-[700] text-[32px] ">
                        <CountUp start={0} end={150} duration={2} suffix='+'/>
                    </h2>
                    <h4 className="text-headingColor font-[600] text-[18px]">Happy Clients</h4>
                </div>

                <div className="mb-10 ">
                    <h2 className="text-headingColor font-[700] text-[32px] ">
                        <CountUp start={0} end={249} duration={2} suffix='+'/>
                    </h2>
                    <h4 className="text-headingColor font-[600] text-[18px]"> Projects Completed</h4>
                </div>

                </div>

                {/*============== Hero Content Right End =========== */}
            </div>

        </div>
    </section>
  )
}

export default Hero