import React from 'react'

const Contact = () => {
  return (
    <section id="contact" className='pb-16'>
        <div className="container">
            <h2 className="text-headingColor font-[700] text-[2.5rem] mb-8">Get in touch</h2>
            <div className="md:flex justify-between items-center">
                <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
                <iframe title='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.905044873618!2d79.87533897507967!3d7.251647214273531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2e8d9a880d5f9%3A0x3a2f0c65581c51d9!2sAppuhamy%20Stores!5e0!3m2!1sen!2slk!4v1742554332403!5m2!1sen!2slk" className='border-0 w-full h-full ' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100 px-4 lg:px-8 py-8 ">
                    <form action="" className="w-full">
                        <div className="mb-5">
                            <input type="text"  name='name' placeholder='Enter you name' className="w-full p-3 focus:outline-none rounded-[5px] " />
                        </div>
                        <div className="mb-5">
                            <input type="email"  name='email' placeholder='Enter you email' className="w-full p-3 focus:outline-none rounded-[5px] " />
                        </div>
                        <div className="mb-5">
                            <input type="text"  name='name' placeholder='Subject' className="w-full p-3 focus:outline-none rounded-[5px] " />
                        </div>
                        <div className="mb-5">
                            <textarea  name='name' rows={3}
                            placeholder='Write your message' className="w-full p-3 focus:outline-none rounded-[5px] "> </textarea>
                        </div>
                        <button className="w-full p-3 focus:outline-none rounded-[5px] bg-smallTextColor text-white hover:bg-headingColor text-center ease-linear duration-150">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Contact